import React, { useState, useEffect } from 'react';
import OrderedSelector from './OrderedSelector';
import SubsectionTitle from './SubsectionTitle';
import Spacer from './Spacer';

function MainQuestionnair(props) {
    const {
        isActive,
        onButtonClick,
        data = {},
        isVideoEnded
    } = props;

    const [selectedOption, setSelectedOption] = useState("1");
    const [isOptionSelected, setIsOptionSelected] = useState(false);
    const [isReasonSelected, setIsReasonSelected] = useState(false);
    const [numberOfSelectedOptions, setNumberOfSelectedOptions] = useState(0);

    useEffect(() => {
        if (isOptionSelected) {
            // Reset the reason selection when a new option is selected
            setIsReasonSelected(false);
        }
    }, [selectedOption]);

    const handleOptionClick = (id) => {
        setIsOptionSelected(true);
        setSelectedOption(id);
    };

    const onBackButtonClick = () => {
        setIsOptionSelected(false);
    }

    const handleReasonSelect = (isSelected) => {
        setIsReasonSelected(isSelected);
    }

    return (
        <div className={`main-questionnair-item ${isActive ? "is-active" : ""}`}>
            <input type="hidden" name={`id${data.id}`} value={data.id} />
            <div className={`main-questionnair-options ${isActive && !isOptionSelected && isVideoEnded ? "is-active" : ""}`}>
                <SubsectionTitle label="避難方法を選んでください" />
                <div className="main-questionnair-options__buttons">
                    {data.options && data.options.map((item) => (
                        <label className="main-questionnair-options__button" key={item.id}>
                            <input type="radio" name={`situation-${data.id}`} value={item.value} onClick={() => handleOptionClick(item.id)} />
                            <span className="main-questionnair-options__button-icon" style={{"--icon" : `url(/images/${item.icon})`}}></span>
                            <span className="main-questionnair-options__button-label">{item.value}</span>
                        </label>
                    ))}
                </div>
            </div>
            <div className={`main-questionnair-reasons ${isOptionSelected ? "is-active" : ""}`}>
                <SubsectionTitle label="選択した理由を、重要視した順に選択してください（3つ以内）" />
                <div className="main-questionnair-reasons-guide">
                    <p>あと<em>{3 - numberOfSelectedOptions}個</em>選択できます。</p>
                </div>
                <Spacer sizePC="12" />
                {data.reasons && data.reasons.map((reasonsGroup) => (
                    <React.Fragment key={reasonsGroup.id}>
                        {reasonsGroup.id === selectedOption &&
                            <OrderedSelector options={reasonsGroup.options} onOptionSelect={handleReasonSelect} setNumberOfSelectedOptions={setNumberOfSelectedOptions} name={`situation-${data.id}`} />
                        }
                    </React.Fragment>
                ))}
                <Spacer sizePC="16" />
                <div className="main-questionnair-item__buttons">
                    <button className="main-questionnair-item__back" type="button" onClick={onBackButtonClick}>避難方法の選択に戻る</button>
                    <button className="main-questionnair-item__submit" type="button" onClick={onButtonClick} disabled={!isReasonSelected}>次へ</button>
                </div>
            </div>
        </div>
    );
}

export default MainQuestionnair;
