function Spacer(props) {
    const {
        sizePC = 0,
        sizeSP = sizePC
    } = props;
    return (
        <div className="spacer" style={{ "--spacer-size-pc": `${sizePC}px`, "--spacer-size-sp": `${sizeSP}px`}}></div>
    );
}

export default Spacer;