import React, { useState, useEffect } from 'react';

const CheckboxOrderForm = (props) => {
    const [checkedOrder, setCheckedOrder] = useState([]);
    const [shuffledOptions, setShuffledOptions] = useState([]);
    const maxChecks = 3;
    const {
        options = [
            "早く避難できると思ったから",
            "安全だと思ったから",
            "エレベーターやエスカレーターは避難に使えないから",
            "階段以外の選択肢がないから",
            "従業員の指示に従った"
        ],
        onOptionSelect = () => {},
        setNumberOfSelectedOptions,
        name,
    } = props;

    useEffect(() => {
        setShuffledOptions(arrayShuffle([...options]));
    }, [options]);

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setCheckedOrder((prevOrder) => {
            if (checked) {
                if (prevOrder.length < maxChecks) {
                    return [...prevOrder, value];
                } else {
                    event.target.checked = false;
                    return prevOrder;
                }
            } else {
                return prevOrder.filter((item) => item !== value);
            }
        });
    };

    useEffect(() => {
        onOptionSelect(!!checkedOrder.length);
        setNumberOfSelectedOptions(checkedOrder.length);
    }, [checkedOrder, onOptionSelect]);

    const hiddenInputs = [];
    for (let i = 0; i < maxChecks; i++) {
        hiddenInputs.push(
            <input
                key={i}
                type="hidden"
                name={`${name}-option-order-${i + 1}`}
                value={checkedOrder[i] || ''}
            />
        );
    }

    const arrayShuffle = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const r = Math.floor(Math.random() * (i + 1));
            [array[i], array[r]] = [array[r], array[i]];
        }
        return array;
    };

    return (
        <div className="reasons-selector-container">
            <div className={`reasons-selector ${checkedOrder.length === 3 ? "reasons-selector--max-selected" : ""}`}>
                {shuffledOptions.map((label, index) => {
                    const orderIndex = checkedOrder.indexOf(label);
                    return (
                        <label key={label} className="reasons-selector__item">
                            <input
                                type="checkbox"
                                value={label}
                                onChange={handleCheckboxChange}
                            />
                            <span className="reasons-selector__item-check">
                                <span className="reasons-selector__item-number">{orderIndex !== -1 && <span className="order-label">{orderIndex + 1}</span>}</span>
                            </span>
                            <span className="reasons-selector__item-label">
                                {label}
                            </span>
                        </label>
                    );
                })}
            </div>
            <div id="hidden-inputs">
                {hiddenInputs}
            </div>
        </div>
    );
};

export default CheckboxOrderForm;
