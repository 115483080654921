import React, { useState, useEffect } from 'react';
import SectionTitle from './SectionTitle';
import FormItemLabel from './FormItemLabel';
import Spacer from './Spacer';
import OrderedSelector from './OrderedSelector';
import SubmitButton from './SubmitButton';
import MainQuestionnairItem from './MainQuestionnairItem';
import questionsData from '../data/questions.json';
import VideoPlayer from './VideoPlayer';

function MainQuestionnair(props) {
    const {
        moveOnToTheNextStep,
        isActive
    } = props;
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
    const [shuffledData, setShuffledData] = useState([]);
    const [isVideoEnded, setIsVideoEnded] = useState(false);
    const [isVideoGuideHide, setIsVideoGuideHide] = useState(false);

    useEffect(() => {
        const shuffled = arrayShuffle(questionsData);
        setShuffledData(shuffled);
    }, []);

    const arrayShuffle = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const r = Math.floor(Math.random() * (i + 1));
            [array[i], array[r]] = [array[r], array[i]];
        }
        return array;
    };

    const handleNextButtonClick = () => {
        setIsVideoEnded(false)
        if(activeQuestionIndex + 1 === shuffledData.length) {
            moveOnToTheNextStep();
            return;
        } else {
            setIsVideoGuideHide(false);
            setActiveQuestionIndex(activeQuestionIndex + 1);
        }
    };
    let activeVideo;
    if (isActive) {
        activeVideo = shuffledData[activeQuestionIndex]?.video;
    }
    const activeVideoTitle = (shuffledData[activeQuestionIndex]?.title);
    return (
        <>
            <div className="main-questionnair-guide">
                <div className="main-questionnair-guide__icon">
                    <img src="/images/icon-exclamation.svg" alt="" width={48} height={48} />
                </div>
                <div className="main-questionnair-guide__content">
                    <p><img src="/images/icon-free-move.svg" alt="" width={48} height={48} className="inline-icon" />動画の上をドラッグして周りを見渡してください。</p>
                </div>
            </div>
            <Spacer sizePC="24" sizeSP="8" />
            <div className="main-questionnair">
            <div className={`questionnair-video`} onMouseDown={() => {setIsVideoGuideHide(true)}} onTouchStart={() => {setIsVideoGuideHide(true)}}>
                <h3 className="questionnair-video__title">状況{activeQuestionIndex + 1}（{activeQuestionIndex + 1}/{shuffledData.length}）</h3>
                <div className={`questionnair-video__guide ${isVideoGuideHide ? "is-hide" : ""}`}>
                    <img src="/images/viewport-guide.svg" width={200} height={200} alt="画面をドラッグして見渡す" />
                </div>
                <div data-vjs-player>
                    {activeVideo && <VideoPlayer src={`/videos/${activeVideo}`} onVideoEnd={() => {setIsVideoEnded(true)}} onVideoPlay={() => {setIsVideoEnded(false);}} />}
                </div>
            </div>
            <div className="main-questionnair-items">
                {shuffledData.map((item, index) => (
                    <MainQuestionnairItem
                        data={item}
                        key={index}
                        isActive={activeQuestionIndex === index}
                        onButtonClick={handleNextButtonClick}
                        isVideoEnded={isVideoEnded}
                    />
                ))}
            </div>
        </div>
        </>
    );
}

export default MainQuestionnair;
