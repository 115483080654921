import React from 'react';

function RadioButtons(props) {
    const {
        options,
        required = false,
        name,
        onChange = () => {}
    } = props;

    // Function to handle change event
    const handleChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <div className="radio-buttons">
            {options ?
                options.map((item, index) => (
                    <label className="radio-button" key={index}>
                        <input
                            name={name}
                            type="radio"
                            value={item}
                            required={required}
                            onChange={handleChange}
                        />
                        <span className="radio-button__check"></span>
                        <span className="radio-button__label">{item}</span>
                    </label>
                ))
            : ""}
        </div>
    );
}

export default RadioButtons;
