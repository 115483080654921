import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import BeforeQuestionnair from '../components/BeforeQuestionnair';
import AfterQuestionnair from '../components/AfterQuestionnair';
import MainQuestionnair from '../components/MainQuestionnair';

function App() {
    const [questionnairestep, setQuestionnairestep] = useState(1);
    const moveOnToTheNextStep = () => {
        setQuestionnairestep(questionnairestep + 1);
    }
    return (
        <>
        <Helmet>
            <title>避難行動に関するアンケート</title>
        </Helmet>
        <form className="questionnaire" method="post" action="/receive.php">
            <div className={`questionnaire-form-container ${questionnairestep === 1 && "is-active"}`}>
            <BeforeQuestionnair moveOnToTheNextStep={moveOnToTheNextStep} />
            </div>
            <div className={`main-questionnaire-container ${questionnairestep === 2 && "is-active"}`}>
            <MainQuestionnair moveOnToTheNextStep={moveOnToTheNextStep} isActive={questionnairestep === 2} /> 
            </div>
            <div className={`questionnaire-form-container ${questionnairestep === 3 && "is-active"}`}>
            <AfterQuestionnair />
            </div>
        </form>
        </>
    );
}

export default App;
