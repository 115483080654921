import React, { useState, useEffect } from 'react';
import SectionTitle from './SectionTitle';
import FormItemLabel from './FormItemLabel';
import Spacer from './Spacer';
import SubmitButton from './SubmitButton';
import RadioButtons from './RadioButtons';

function BeforeQuestionnaire(props) {
    const { moveOnToTheNextStep } = props;

    const [formValues, setFormValues] = useState({
        gender: '',
        age: '',
        wheelchair: ''
    });

    const [isFormComplete, setIsFormComplete] = useState(false);

    const [currentTimestamp, setCurrentTimestamp] = useState('');

    useEffect(() => {
        const now = new Date();
        now.setHours(now.getHours() + 9);
        const formattedTimestamp = now.toISOString().slice(0, 19).replace('T', ' ');
        setCurrentTimestamp(formattedTimestamp);
    }, []);

    const handleChange = (name, value) => {
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    useEffect(() => {
        const allFilled = Object.values(formValues).every(value => value !== '');
        setIsFormComplete(allFilled);
    }, [formValues]);

    const handleSubmitButtonClick = () => {
        moveOnToTheNextStep();
    };

    const [questionnaireStep, setQuestionnaireStep] = useState(0);

    const handleNextButtonClick = () => {
        if (isFormComplete) {
            setQuestionnaireStep(questionnaireStep + 1);
        } else {
            alert("全ての必須項目を入力してください。");
        }
    };

    return (
        <>
            <div className={`before-questionnaire questionnaire-form-window ${questionnaireStep === 0 ? "is-active" : ""}`}>
                <SectionTitle label="あなたについてお聞きします" />
                <div className="questionnaire-form">
                    <input name="回答日時" type="hidden" value={currentTimestamp} />
                    <div className="questionnaire-form-item">
                        <FormItemLabel text="性別" required />
                        <RadioButtons
                            required
                            name="gender"
                            options={["男性", "女性"]}
                            onChange={value => handleChange('gender', value)}
                        />
                    </div>
                    <div className="questionnaire-form-item">
                        <FormItemLabel text="年齢" required />
                        <RadioButtons
                            required
                            name="age"
                            options={["70才以上", "50-69才", "30-49才", "29才以下"]}
                            onChange={value => handleChange('age', value)}
                        />
                    </div>
                    <div className="questionnaire-form-item">
                        <FormItemLabel text="日常の歩行の困難度について" required />
                        <RadioButtons
                            required
                            name="wheelchair"
                            options={["特に支障なし", "杖や歩行補助器具を利用している", "車いすを利用している", "妊婦・ベビーカーを利用している", "その他"]}
                            onChange={value => handleChange('wheelchair', value)}
                        />
                    </div>
                </div>
                <Spacer sizePC="24" />
                {!isFormComplete ?
                    <>
                        <div className="form-info">
                            <p>すべての項目を入力してください</p>
                        </div>
                        <Spacer sizePC="48" />
                    </>
                : ""}
                <SubmitButton label="次へ進む" onClick={handleNextButtonClick} disabled={!isFormComplete} />
            </div>
            <div className={`before-questionnaire questionnaire-form-window ${questionnaireStep === 1 ? "is-active" : ""}`}>
                <div className="questionnaire-situation">
                    <h3 className="questionnaire-situation__title">状況説明</h3>
                    <div className="questionnaire-situation__content">
                        <p>あなたは今、初めて訪れた百貨店の5階でショッピングを楽しんでいます。<br />突然、3階で火災が発生し、すぐに避難するよう放送が流れました。</p>
                        <p>※本アンケートでは避難放送等の音声は流れません</p>
                        <p><em>次のページに回答方法の説明の動画がありますので、必ず説明の音声も聞いてご回答ください。</em></p>
                    </div>
                </div>
                <Spacer sizePC="48" />
                {!isFormComplete ?
                    <>
                        <div className="form-info">
                            <p>すべての項目を入力してください</p>
                        </div>
                        <Spacer sizePC="48" />
                    </>
                : ""}
                <SubmitButton label="次へ進む" onClick={handleNextButtonClick} />
            </div>
            <div className={`before-questionnaire questionnaire-form-window ${questionnaireStep === 2 ? "is-active" : ""}`}>
                <div className="questionnaire-info">
                    <h3 className="questionnaire-info__title">アンケート回答方法について</h3>
                    <div className="questionnaire-info__content">
                        <p>下の「アンケート開始ボタン」をクリックすると、映像が表示されます。<br />マウスを左クリックしたまま視点をいろんな方向へ動かしてみてください。</p>
                        <p>周囲の状況を確認した後に、あまり深く考えず、直感で「階段」、「エレベーター」、「エスカレーター」のどの方法を使って避難するか選択してください。</p>
                        <p>続けて、その避難方法を選択した理由（重要視した順に3つ以内）を選択してください。</p>
                        <p>状況の異なる映像が全部で12ケース出てきますので、全ての設問にご回答願います。</p>
                        <p>（回答に要する時間15分程度）</p>
                        <p><em>回答方法の説明の動画がございます、必ず説明の音声も聞いてご回答ください。</em></p>
                        <Spacer sizePC="48" />
                        <h4 className="subsection-title">回答方法（動画による説明）</h4>
                        <p>もう一度説明の動画を見るには画面左下の▶をクリックしてください</p>
                        <Spacer sizePC="24" />
                        <video className="questionnaire-info__tutrial" src="/videos/tutrial.mp4" controls></video>
                    </div>
                </div>
                <Spacer sizePC="48" />
                <SubmitButton label="避難を開始する" onClick={handleSubmitButtonClick} />
            </div>
        </>
    );
}

export default BeforeQuestionnaire;
