function SubmitButton(props) {
    const {
        label = "送信する",
        onClick = null,
        type = "button",
        disabled
    } = props;
    return (
        <button type={type} className="submit-button" onClick={onClick} disabled={disabled}>{label}</button>
    );
}

export default SubmitButton;