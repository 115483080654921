function FormItemLabel(props) {
    return (
        <span className="form-item-label">
            <span className="form-item-label__text">{props.text}</span>
            {props.required &&
                <span className="form-item-label__required">必須</span>
            }
        </span>
    );
}

export default FormItemLabel;