function SubmitButton(props) {
    const {
        label = "詳しく見る",
        href = "/",
    } = props;
    return (
        <a className="button" href={href} >{label}</a>
    );
}

export default SubmitButton;