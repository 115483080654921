import React, { useState, useEffect } from 'react';
import SectionTitle from './SectionTitle';
import SubsectionTitle from './SubsectionTitle';
import FormItemLabel from './FormItemLabel';
import Spacer from './Spacer';
import SubmitButton from './SubmitButton';
import RadioButtons from './RadioButtons';

function AfterQuestionnaire(props) {
    // State to track form values
    const [formValues, setFormValues] = useState({
        'after-question-01': '',
        'after-question-02': '',
        'after-question-03': '',
        'after-question-04': '',
        'after-question-05': ''
    });

    // State to track if the form is complete
    const [isFormComplete, setIsFormComplete] = useState(false);

    // Function to handle changes in the form
    const handleChange = (name, value) => {
        setFormValues({
            ...formValues,
            [name]: value
        });
        console.log("test;");
    };

    // Check if all required fields are filled
    useEffect(() => {
        const allFilled = Object.values(formValues).every(value => value !== '');
        setIsFormComplete(allFilled);
    }, [formValues]);

    return (
        <div className="after-questionnaire questionnaire-form-window is-active">
            <SectionTitle label="最後に以下のアンケートにお答えください。" />
            <div className="questionnaire-form">
                <div className="questionnaire-form-item">
                    <FormItemLabel text="火災の避難にエレベーターを使えないことを知っていましたか" required />
                    <RadioButtons
                        required
                        name="after-question-01"
                        options={["はい", "いいえ"]}
                        onChange={value => handleChange('after-question-01', value)}
                    />
                </div>
                <div className="questionnaire-form-item">
                    <FormItemLabel text="火災の避難にエスカレーターを使えないことを知っていましたか" required />
                    <RadioButtons
                        required
                        name="after-question-02"
                        options={["はい", "いいえ"]}
                        onChange={value => handleChange('after-question-02', value)}
                    />
                </div>
                <div className="questionnaire-form-item">
                    <FormItemLabel text="火災の避難にエレベーターが使えるとしたら使いたいですか" required />
                    <RadioButtons
                        required
                        name="after-question-03"
                        options={["はい", "いいえ"]}
                        onChange={value => handleChange('after-question-03', value)}
                    />
                </div>
                <div className="questionnaire-form-item">
                    <FormItemLabel text="火災の避難にエスカレーターが使えるとしたら使いたいですか" required />
                    <RadioButtons
                        required
                        name="after-question-04"
                        options={["はい", "いいえ"]}
                        onChange={value => handleChange('after-question-04', value)}
                    />
                </div>
                <div className="questionnaire-form-item">
                    <FormItemLabel text="このサインの違いをご存じですか" required />
                    <img src="/images/guide-light.png" alt="誘導灯" width={444} height={220} style={{maxWidth:400}} />
                    <Spacer sizePC="16" />
                    <RadioButtons
                        required
                        name="after-question-05"
                        options={["はい", "いいえ"]}
                        onChange={value => handleChange('after-question-05', value)}
                    />
                </div>
            </div>
            <Spacer sizePC="48" />
            {!isFormComplete ?
                <>
                    <div className="form-info">
                        <p>すべての項目を入力してください</p>
                    </div>
                    <Spacer sizePC="48" />
                </>
            : ""}
            <SubmitButton type="submit" label="送信する" disabled={!isFormComplete} />
        </div>
    );
}

export default AfterQuestionnaire;
