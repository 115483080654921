import Button from '../components/Button';
import Spacer from '../components/Spacer';
import React, { useState } from 'react';

function App() {
    const [questionnairestep, setQuestionnairestep] = useState(1);
    const moveOnToTheNextStep = () => {
        setQuestionnairestep(questionnairestep + 1);
    }
    return (
        <div className="home">
            <Spacer sizePC="80" />
            <ul className="questionnaire-menu">
                <li className="questionnaire-menu-item">
                    <div className="questionnaire-menu-item__inner" href="/department-store">
                        <div className="questionnaire-menu-item__content">
                            <h3 className="questionnaire-menu-item__title">避難行動に関するアンケート</h3>
                            <p className="questionnaire-menu-item__description">火災の際に”避難者がどのような行動をとるか”をアンケートします。</p>
                            <Button href="./#/department-store" label="アンケートを開始する" />
                        </div>
                        <div className="questionnaire-menu-item__image">
                            <img src="/images/questionnaire-menu-item-01.jpg" alt="火災が発生したショッピングモール" width="480" height="320" />
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default App;
