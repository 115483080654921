import React, { useState, useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-vr/dist/videojs-vr.css';
import 'videojs-vr';

function Player(props) {
    const videoNode = useRef(null);
    const [player, setPlayer] = useState(null);
    const videoJsOptions = {
        controls: true,
        muted: true,
        sources: [{
            src: props.src,
            type: 'video/mp4',
        }],
        controls: false,
        plugins: {
            vr: {
                projection: '360',
                debug: true,
                forceCardboard: false,
                sphereDetail: 64,
                omnitone: false,
                motionControls: false,
            }
        }
    };

    useEffect(() => {
        if (videoNode.current) {
            const playerInstance = videojs(videoNode.current, videoJsOptions, function() {
                console.log('Player is Ready', this);
                this.vr(videoJsOptions.plugins.vr);
            });

            // 再生開始時に関数を実行
            playerInstance.on('play', () => {
                if (props.onVideoPlay) {
                    props.onVideoPlay();
                }
            });

            // 再生終了時に関数を実行
            playerInstance.on('ended', () => {
                if (props.onVideoEnd) {
                    props.onVideoEnd();
                }
            });

            setPlayer(playerInstance);

        }
    }, []);

    useEffect(() => {
        if (player) {
            player.src({ src: props.src, type: 'video/mp4' });
        }
    }, [props.src, player]);

    return (
        <div className="c-player video-player">
            <div className="c-player__screen" data-vjs-player="true">
                <video
                    ref={videoNode}
                    className="video-js"
                    muted
                    controls
                    playsInline
                    autoPlay
                />
            </div>
        </div>
    );
}

export default React.memo(Player);
