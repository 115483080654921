
import React, { useState } from 'react';
import {
  HashRouter,
  Route,
  Routes
} from "react-router-dom";
import { Helmet } from 'react-helmet';
import DepartmentStore from './pages/department-store';
import Home from './pages/index';

import './App.css';


function App() {
  const [questionnairestep, setQuestionnairestep] = useState(1);
  const moveOnToTheNextStep = () => {
    setQuestionnairestep(questionnairestep + 1);
  }
  return (
    <div className="app">
      <Helmet>
        <html lang="ja" />
        <title>アンケート</title>
      </Helmet>
      <header className="site-header">
        <a className="site-header__logo" href="/"><img src="/images/logo.svg" alt="避難行動に関するアンケート" /></a>
      </header>
      <HashRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/department-store' element={<DepartmentStore />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
